<template>
  <aside class="message">

    <p>
      <img :src="settings.upload + settings.logo" alt="" />
    </p>

    <section>

      <h1>Interface</h1>

      <h2>Partenaire</h2>

      <p>Veuillez saisir vos identifiants de <br/>
        connexion pour accéder à l'interface
      </p>

    </section>

    <p v-if="!cookies" class="consent" @click="validCookies()">
      Afin de faciliter la navigation, ce site utilise un système de cookies 🍪.
      Ces cookies sont nécessaires pour le bon fonctionnement de notre site internet et ne peuvent pas être désactivés.
      Pour plus d'informations, veuillez consulter le lien "Données personnelles" en bas de page.
      <u>J'accepte</u>
    </p>

    <code><u @click="show=1">Données personnelles</u> - Version {{ settings.version }} - Développement <u @click="goto()">endorfine.fr</u></code>

    <section v-if="show" class="privacy">
      <p>
        <u @click="show=0">X Fermer</u>
      </p>
      <privacy></privacy>
    </section>

  </aside>
</template>

<script>
import settings from '@/settings.js'
import Privacy from '@/components/Privacy.vue'

export default {
  name: 'LoginMessage',
  components: {
    Privacy
  },
  data () {
    return {
      settings,
      show: 0
    }
  },
  computed: {
    cookies: function () {
      return this.$store.getters.cookies
    }
  },
  methods: {
    goto: function () {
      window.location.href = 'https://endorfine.fr'
    },
    validCookies: function () {
      return this.$store.commit('set_cookies')
    }
  }
}
</script>
